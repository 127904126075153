<template>
  <div class="text-center">
    <v-snackbar
      v-model="openSnackbar"
      vertical
      color="info"
    >
      {{ messageSnackbar }}

      <template v-slot:action="{ attrs }">
        <v-row class="mx-auto text-center">
        <v-btn
          v-bind="attrs"
          dark
          class="mr-4"
          small
          color="green"
          @click="confirmDelete"
          >Aceptar</v-btn
        >
        <v-btn v-bind="attrs" dark class="ml-4" small color="red" @click="openSnackbar = false"
          >Cancelar</v-btn
        >
      </v-row>
      </template>
    </v-snackbar>

  </div>
</template>

<script>
export default {
  name: "snackbar-confirm",
  props: {
    messageSnackbar: {
      type: String,
      default: () => "¿Desea confirmar acción?",
    },
  },
  data: function() {
    return {
      openSnackbar: false,
    };
  },
  methods: {
   confirmDelete(){
     this.$emit('successResponse');
     this.openSnackbar= false
   } 
  },
};
</script>
